<template>
  <div class="newsletter-page-container">
    <div class="aqz-container">
      <breadcrumb :items="pathBreadcrumb"/>
      <b-row>
        <b-col md="11" class="mx-auto">
          <div class="wrapp-info">
            <div class="wrapp-form-newsletter">
              <div class="form-intro">
                <h1 class="title title-small f-book">{{ $t('newsletterPage.title') }}</h1>
                <p class="t-normal">{{ $t('newsletterPage.intro') }}</p>
              </div>
              <div class="newsletter-image">
                <img v-lazy="newsletterImage" class="img-fluid">
              </div>
              <b-form class="form-newsletter reduce-size" @submit.prevent="submitForm" data-vv-scope="form-cta-newsletter" ref="scrollAnchor">
                <b-form-group id="email" label-for="email">
                  <base-input
                    name="email"
                    class="form-border-line"
                    v-model.trim="newsletter.email"
                    v-validate="'required|email'"
                    type="email"
                    :placeholder="`*${$t('email')}`"
                    :validations="[
                      {
                        condition: errors.has('form-cta-newsletter.email'),
                        text: errors.first('form-cta-newsletter.email')
                      }
                    ]"
                  >
                  </base-input>
                  <div class="valid-feedback"><div> {{$t('newsletter_success')}} </div></div>
                </b-form-group>
                <b-form-group id="checkbox-privacy-newsletter" class="checkbox-privacy">
                  <span class="privacy-info t-small" v-html="labelInputPrivacy">
                  </span>
                </b-form-group>
                 <div class="newsletter-content">
                  <!-- <div class="wrapp-receiving">
                    <p class="text-line">{{ $t('newsletterPage.receivingOptions')}}</p>
                    <b-form-group id="newsletter-groupReciving" label-for="newsletter-receiving">
                      <b-form-checkbox-group
                      id="newsletter-receiving"
                      v-validate="'required'"
                      v-model="newsletter.receiving"
                      :options="genderOptions"
                      text-field="label"
                      name="receiving-options"
                      />
                      <b-form-invalid-feedback :state="!errors.has('form-cta-newsletter.receiving-options')"><div>{{errors.first('form-cta-newsletter.receiving-options')}}</div></b-form-invalid-feedback>
                    </b-form-group>
                  </div> -->
                  <p class="text-line">
                    {{$t('newsletterPage.privacies-step-intro-pt-1')}}
                  </p>
                  <p class="text-line">
                    {{$t('newsletterPage.privacies-step-intro-pt-2')}}
                  </p>
                  <div class="privacy-block">
                    <div v-for="(value, key) in newsletter.privacy" :key="key" class="privacy-terms">
                      <b-form-group :id="'checkbox_'+key" :label-for="key">
                        <base-check-box
                          :name="key"
                          :key="key"
                          v-validate="(privacyMandatory[key] ? 'required:true' : '')"
                          v-model="newsletter.privacy[key]"
                          :label="labelCheckbox[key]"
                          :use_prevent="false"
                          :validations="[
                            {
                              condition: errors.has(`form-cta-newsletter.${key}`),
                              text: errors.first(`form-cta-newsletter.${key}`)
                            }
                          ]"
                        >
                        </base-check-box>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <re-CAPTCHA
                  ref="nlReCaptcha"
                  :isReCaptchaVerified.sync="isReCaptchaVerified"
                  :isReCaptchaInvalid.sync="isReCaptchaInvalid"
                  :reCaptchaToken.sync="reCaptchaToken">
                  <!-- <template v-slot:button>
                    <button>Click me</button>
                  </template> -->
                </re-CAPTCHA>
                <div class="form-actions">
                  <button type="submit" class="btn btn-primary full-width">{{ $t('subscribe') }}</button>
                </div>
              </b-form>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import Newsletter from '@/services/Newsletter'
import Breadcrumb from '@/components/Breadcrumb'
// import Config from '@/config'
import { getBreadcrumbFromURL } from '@/helpers/utils'
import { EventBus } from '@/helpers'
import { pathTranslate } from '@/helpers/routeHelpers'
import Logger from '@/services/Logger'
import reCAPTCHA from '@/theme/components/reCAPTCHA/reCAPTCHA.vue'

export default {
  inject: ['$validator'],
  data () {
    return {
      newsletter: {
        email: '',
        receiving: [],
        privacy: {
          marketingTerms: false,
          profilingTerms: false
        }
      },
      privacyMandatory: { 'marketingTerms': true },
      showCtaNewsletter: null,
      isReCaptchaVerified: false,
      isReCaptchaInvalid: false,
      reCaptchaToken: undefined
    }
  },
  mounted () {
    if (process.client) {
      let newsletterCookieVal = !!global.$cookies.get('hide-cta-newsletter')
      this.showCtaNewsletter = !newsletterCookieVal
    }
  },
  computed: {
    // genderOptions () {
    //   const _this = this
    //   return Config.Theme.forms.genderNewsletter.map((item, index) => ({
    //     value: Config.Theme.forms.genderNewsletterValue[index],
    //     label: _this.$t('gender-types.' + item)
    //   }))
    // },
    labelCheckbox () {
      return {
        'marketingTerms': this.labelLinkPrivacy('newsletterPage.termsMarketing'),
        // 'terms1': this.$t('newsletter.terms1'),
        // 'terms2': this.$t('newsletter.terms2'),
        'profilingTerms': this.labelLinkPrivacy('newsletterPage.termsProfiling')
      }
    },
    labelInputPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t('newsletterPage.input-privacy', { linkPrivacy })
    },
    newsletterImage () {
      let newsletterMedias = this.$store.getters['storeConfig/pageNewsletterMedia']
      if (this.$store.state.ui.isMobile) {
        return newsletterMedias.mobileMedia
      } else {
        return newsletterMedias.desktopMedia
      }
    },
    pathBreadcrumb () {
      let title
      if (this.$route.meta.title) {
        title = this.$route.meta.title
      }
      return getBreadcrumbFromURL({ fullPath: this.$route.path, translate: this.$t.bind(this), title, i18nObj: this.i18nObj })
    }
  },
  methods: {
    showContent () {
      if (this.$refs.newsletterScrollbar) {
        const osInstance = this.$refs.newsletterScrollbar.osInstance()
        osInstance.scrollStop().scroll('100%')
      }
    },
    labelLinkPrivacy (textLabel) {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t(textLabel, { linkPrivacy })
    },
    clearForm () {
      this.newsletter.email = ''
      this.newsletter.privacy.marketingTerms = false
      this.newsletter.privacy.profilingTerms = false
      this.$validator.reset()
    },
    submitForm ($event) {
      const _this = this
      this.$validator.validateAll('form-cta-newsletter')
        .then(isValid => {
          if (isValid) {
            if (_this.reCaptchaToken) {
              _this.$store.commit('ui/showLoader')
              return Newsletter.subscribeCustomer(
                _this.$store.state.ui.storeViewCode,
                _this.newsletter.email,
                _this.reCaptchaToken,
                this.newsletter.privacy.marketingTerms,
                this.newsletter.privacy.profilingTerms
              )
                .then((res) => {
                  if (res.data !== 'OK') {
                    throw new Error('error')
                  }
                  EventBus.$emit('track:newsletter', { email: _this.newsletter.email, $event })
                  _this.$refs.nlReCaptcha.$refs.vueReCaptcha.reset()
                  _this.reCaptchaToken = ''
                  $('#email .valid-feedback').css('display', 'block')
                  setTimeout(function () {
                    _this.hideModal()
                  }, 1500)
                  // toast.success(_this.$t('newsletter_success'))
                  _this.clearForm()
                })
                .catch((err) => {
                  Logger.error(err)
                  $('#email input').addClass('is-invalid')
                  $('#email .invalid-feedback').html(_this.$t('newsletter_server_error'))
                  setTimeout(function () {
                    $('#email .invalid-feedback').html('')
                  }, 2500)
                })
                .finally(() => {
                  _this.$store.commit('ui/hideLoader')
                })
            } else {
              _this.isReCaptchaInvalid = true
            }
          }
        })
    },
    hideModal () {
      this.showCtaNewsletter = false
      this.closeNewsletterBlock()
    },
    closeNewsletterBlock () {
      global.$cookies.set('hide-cta-newsletter', true)
    }
  },
  components: {
    BaseInput,
    BaseCheckBox,
    Breadcrumb,
    reCAPTCHA
  }
}
</script>
