var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"newsletter-page-container"},[_c('div',{staticClass:"aqz-container"},[_c('breadcrumb',{attrs:{"items":_vm.pathBreadcrumb}}),_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"md":"11"}},[_c('div',{staticClass:"wrapp-info"},[_c('div',{staticClass:"wrapp-form-newsletter"},[_c('div',{staticClass:"form-intro"},[_c('h1',{staticClass:"title title-small f-book"},[_vm._v(_vm._s(_vm.$t('newsletterPage.title')))]),_c('p',{staticClass:"t-normal"},[_vm._v(_vm._s(_vm.$t('newsletterPage.intro')))])]),_c('div',{staticClass:"newsletter-image"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.newsletterImage),expression:"newsletterImage"}],staticClass:"img-fluid"})]),_c('b-form',{ref:"scrollAnchor",staticClass:"form-newsletter reduce-size",attrs:{"data-vv-scope":"form-cta-newsletter"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('b-form-group',{attrs:{"id":"email","label-for":"email"}},[_c('base-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],staticClass:"form-border-line",attrs:{"name":"email","type":"email","placeholder":("*" + (_vm.$t('email'))),"validations":[
                    {
                      condition: _vm.errors.has('form-cta-newsletter.email'),
                      text: _vm.errors.first('form-cta-newsletter.email')
                    }
                  ]},model:{value:(_vm.newsletter.email),callback:function ($$v) {_vm.$set(_vm.newsletter, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"newsletter.email"}}),_c('div',{staticClass:"valid-feedback"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t('newsletter_success'))+" ")])])],1),_c('b-form-group',{staticClass:"checkbox-privacy",attrs:{"id":"checkbox-privacy-newsletter"}},[_c('span',{staticClass:"privacy-info t-small",domProps:{"innerHTML":_vm._s(_vm.labelInputPrivacy)}})]),_c('div',{staticClass:"newsletter-content"},[_c('p',{staticClass:"text-line"},[_vm._v("\n                  "+_vm._s(_vm.$t('newsletterPage.privacies-step-intro-pt-1'))+"\n                ")]),_c('p',{staticClass:"text-line"},[_vm._v("\n                  "+_vm._s(_vm.$t('newsletterPage.privacies-step-intro-pt-2'))+"\n                ")]),_c('div',{staticClass:"privacy-block"},_vm._l((_vm.newsletter.privacy),function(value,key){return _c('div',{key:key,staticClass:"privacy-terms"},[_c('b-form-group',{attrs:{"id":'checkbox_'+key,"label-for":key}},[_c('base-check-box',{directives:[{name:"validate",rawName:"v-validate",value:((_vm.privacyMandatory[key] ? 'required:true' : '')),expression:"(privacyMandatory[key] ? 'required:true' : '')"}],key:key,attrs:{"name":key,"label":_vm.labelCheckbox[key],"use_prevent":false,"validations":[
                          {
                            condition: _vm.errors.has(("form-cta-newsletter." + key)),
                            text: _vm.errors.first(("form-cta-newsletter." + key))
                          }
                        ]},model:{value:(_vm.newsletter.privacy[key]),callback:function ($$v) {_vm.$set(_vm.newsletter.privacy, key, $$v)},expression:"newsletter.privacy[key]"}})],1)],1)}),0)]),_c('re-CAPTCHA',{ref:"nlReCaptcha",attrs:{"isReCaptchaVerified":_vm.isReCaptchaVerified,"isReCaptchaInvalid":_vm.isReCaptchaInvalid,"reCaptchaToken":_vm.reCaptchaToken},on:{"update:isReCaptchaVerified":function($event){_vm.isReCaptchaVerified=$event},"update:is-re-captcha-verified":function($event){_vm.isReCaptchaVerified=$event},"update:isReCaptchaInvalid":function($event){_vm.isReCaptchaInvalid=$event},"update:is-re-captcha-invalid":function($event){_vm.isReCaptchaInvalid=$event},"update:reCaptchaToken":function($event){_vm.reCaptchaToken=$event},"update:re-captcha-token":function($event){_vm.reCaptchaToken=$event}}}),_c('div',{staticClass:"form-actions"},[_c('button',{staticClass:"btn btn-primary full-width",attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('subscribe')))])])],1)],1)])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }